export default 
`使用时请将内容替换为自己的内容。

请阅读下方文本熟悉工具使用方法。

<img style="width: 120px" src="https://i.loli.net/2019/04/01/5ca1766ad4e58.png"/>

## 1. Markdown Nice 简介

- 支持自定义样式的 Markdown 编辑器
- 支持微信公众号排版
- 支持知乎、掘金、博客园和CSDN等平台
- 内容和自定义样式浏览器中实时保存
- 欢迎登录 GitHub 账号，提交自定义主题

## 2. 示例文章

- <span style="color:orangered;font-weight:bold;">小橙：</span>[终于等到你，公众号排版神器](https://mp.weixin.qq.com/s/raFgkqlV5hZmrXiEWVAyfQ)
- <span style="color:#773098;font-weight:bold;">姹紫：</span>[JavaScript 数据结构与算法之美](https://mp.weixin.qq.com/s/KmoRDGdJLZ7reMfTDDaFGg)
- <span style="color:#35b378;font-weight:bold;">绿意：</span>[前端硬核面试专题之 CSS 55 问](https://mp.weixin.qq.com/s/SVKMsQtOLNqYXeT_f95FUw)

## 3. 标题

在文字写书写不同数量的\`#\`可以完成不同的标题，如下：
# 一级标题
## 二级标题
### 三级标题

## 4. 无序列表

无序列表的使用，在符号\`-\`后加空格使用。如下：

- 无序列表1
- 无序列表2
- 无序列表

如果要控制列表的层级，则需要在符号\`-\`前使用空格。如下：

- 无序列表1
- 无序列表2
  - 无序列表2.1
  - 无序列表2.2

粘贴到微信编辑器后后**无序列表整体样式与微信一致**。

## 5. 有序列表

有序列表的使用，在数字及符号\`.\`后加空格后输入内容，如下：

1. 有序列表1
2. 有序列表2
3. 有序列表3

粘贴到微信编辑器后后**有序列表整体样式与微信一致**。

## 6. 引用

引用的格式是使用符号\`>\`后面书写文字，及可以使用引用。如下：

> 读一本好书，就是在和高尚的人谈话。 ——歌德

> 雇用制度对工人不利，但工人根本无力摆脱这个制度。 ——阮一峰

## 7. 粗体和斜体

粗体的使用是在需要加粗的文字前后各加两个\`*\`。

而斜体的使用则是在需要斜体的文字前后各加一个\`*\`。

如果要使用粗体和斜体，那么就是在需要操作的文字前后各加三个\`*\`。如下：

**这个是粗体**

*这个是斜体*

***这个是粗体加斜体***

## 8. 链接

微信公众号仅支持公众号文章链接，即域名为\`https://mp.weixin.qq.com/\`的合法链接。使用方法如下所示：

对于该论述，欢迎读者查阅之前发过的文章，[你是《未来世界的幸存者》么？](https://mp.weixin.qq.com/s/s5IhxV2ooX3JN_X416nidA)

## 9. 脚注

脚注与链接的区别如下所示：

\`\`\`md
链接：[文字](链接)
脚注：[文字](脚注解释 "脚注名字")
\`\`\`

有人认为在[大前端时代](https://en.wikipedia.org/wiki/Front-end_web_development "Front-end web development")的背景下，移动端开发（Android、IOS）将逐步退出历史舞台。

[全栈工程师](是指掌握多种技能，并能利用多种技能独立完成产品的人。 "什么是全栈工程师")在业务开发流程中起到了至关重要的作用。

脚注内容请拉到最下面观看。

## 10. 图片

插入图片，如果是行内图片则无图例，否则有图例，格式如下：

![这里写图片描述](https://i.loli.net/2019/03/30/5c9eff84d81c4.png)

**可使用上方工具上传本地图片，感谢 SM.MS 图床助力，目前图床还不够稳定，会存在粘贴失败的现象，自定义图床正在开发中。**

## 11. 代码块

如果在一个行内需要引用代码，只要用反引号引起来就好，如下：

Use the \`printf()\`  function.

在需要高亮的代码块的前一行及后一行使用三个反引号，同时第一行反引号后面表面代码块所使用的语言，如下：

\`\`\`java
// FileName: HelloWorld.java
public class HelloWorld {
  // Java 入口程序，程序从此入口
  public static void main(String[] args) {
    System.out.println("Hello,World!"); // 向控制台打印一条语句
  }
}
\`\`\`

如果想要更换代码主题，可在上方挑选，不支持代码主题自定义。

其中**微信代码主题与微信官方一致**，有以下注意事项：

- 带行号且不换行，代码大小与官方一致
- 需要在代码块处标志语言，否则无法高亮
- 粘贴到公众号后，用鼠标点代码块内外一次，完成高亮

**其他主题不带行号，可自定义是否换行，代码大小与当前编辑器一致**

## 12. 分割线

可以在一行中用三个以上的星号、减号、底线来建立一个分隔线，同时需要在分隔线的上面空一行。如下：

---
****
___

## 13. 删除线

删除线的使用，在需要删除的文字前后各使用两个\`~\`，如下：

~~这是要被删除的内容。~~

## 14. 表格

可以使用冒号来定义表格的对齐方式，如下：

| 姓名 | 年龄 | 工作 |
| :--- |:---:| ---:|
| 小可爱 | 18 | 吃可爱多 |
| 小勇敢 | 20 | 爬勇敢树 |
| 小机智 | 22 | 看机智书 |

## 15. 上标和下标

使用两个\`^\`包围可作为上标，如下：19^th^

使用两个\`~\`包围可作为下标，如下：H~2~O

## 16. 数学公式

需要使用数学公式将代码块去除即可

\`\`\`
行内公式使用方法，比如这个著名的公式：$E=mc^2$，源于爱因斯坦

块公式使用方法如下：

$$H(D_2) = -(\\frac{2}{4}\\ log_2 \\frac{2}{4} + \\frac{2}{4}\\ log_2 \\frac{2}{4}) = 1$$

矩阵：

$$
  \\begin{pmatrix}
  1 & a_1 & a_1^2 & \\cdots & a_1^n \\\\
  1 & a_2 & a_2^2 & \\cdots & a_2^n \\\\
  \\vdots & \\vdots & \\vdots & \\ddots & \\vdots \\\\
  1 & a_m & a_m^2 & \\cdots & a_m^n \\\\
  \\end{pmatrix}
$$
\`\`\`

公式由于微信不支持，目前的解决方案就是转成图片放到微信中，由于图床稳定性，目前还在测试

可考虑手动将转成图片的公式保存下来上传到微信上再将链接取回则万无一失

不推荐使用行内公式，微信会将图片放大

## 17. HTML

支持原生 HTML 语法，除个别情况外，建议尽少使用，如下：

<span style="display:block;text-align:right;color:orangered;">橙色居右</span>
<span style="display:block;text-align:center;color:orangered;">橙色居中</span>

## 18. UML

不支持，推荐使用\`https://www.draw.io/\` 或 \`https://www.processon.com/\` 制作后再导入图片`;